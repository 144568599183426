<template>
  <div
    class="tab-pane fade"
    id="list-settings122"
    role="tabpanel"
    aria-labelledby="list-settings-list122"
  >
    <div v-if="permissionIsAssigned('ip-address-read')">
      <h4>
        <div class="row">
          <div class="col-lg-6" style="align-self: center;">
            Geolocation Management
          </div>
          <div
            v-if="permissionIsAssigned('ip-address-create')"
            class="col-lg-6"
          >
            <div class="row">
              <div class="col-lg-3 offset-9 py-4">
                <button
                  v-if="permissionIsAssigned('ip-address-create')"
                  @click="launch_add_geo_location()"
                  style="float: right;"
                  type="button"
                  class="btn btn-sm btn-secondary m-1 text-white btn-raised ripple"
                >
                  Add geolocation
                </button>
              </div>
            </div>
          </div>
        </div>
      </h4>
      <div class="main-content mt-0 px-0">
        <div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-left" scope="col">Address</th>
                  <th class="text-left" scope="col">
                    Latitude
                  </th>
                  <th class="text-left" scope="col">
                    Longitude
                  </th>
                  <th
                    v-if="permissionIsAssigned('ip-address-delete')"
                    class="text-left"
                    scope="col"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="location in authCompanyGeoLocations"
                  :key="location.id"
                >
                  <td class="text-left">
                    {{ location.address }}
                  </td>
                  <td class="text-left">
                    {{ location.latitude }}
                  </td>
                  <td class="text-left">
                    {{ location.longitude }}
                  </td>
                  <td
                    v-if="permissionIsAssigned('ip-address-delete')"
                    class="text-left"
                  >
                    <div class="row">
                      <i
                        @click="delete_geo_location(location)"
                        class="col-sm-5 py-4 text-center fal fa-trash ml-2"
                        style="color: #069 !important; font-size: 125%; cursor: pointer;"
                      ></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="geo_location_dialog" max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Geolocation</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form @submit.prevent>
              <div class="container-fluid">
                <v-row>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Latitude
                      <i
                        class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="geo_location_add_latitude"
                      solo
                      class="mt-2"
                      dense
                      @input="validateLatitude"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Longitude
                      <i
                        class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="geo_location_add_longitude"
                      solo
                      class="mt-2"
                      dense
                      @input="validateLongitude"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <span style="color: #111d5e;"
                      >Address
                      <i
                        class="fal fa-asterisk"
                        style="font-size: 8px; color: #f00; position: relative; bottom: 5px;"
                      ></i
                    ></span>
                    <v-text-field
                      v-model="geo_location_add_address"
                      solo
                      class="mt-2"
                      dense
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="remove-hint">
                    <p>
                      <span style="font-weight: bold;">Note: </span>The address
                      is approximate only, not exact.
                    </p>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                    offset-md="4"
                    offset-sm="4"
                    class="remove-hint"
                  >
                    <button
                      @click="
                        geo_location_add_submit ? null : submit_geo_location()
                      "
                      style="width: 99%;"
                      type="button"
                      class="btn btn-secondary m-1 text-white btn-raised ripple"
                    >
                      {{ geo_location_add_text }}
                      <v-icon
                        :style="
                          !geo_location_add_submit
                            ? 'display: none;'
                            : 'color: #fff; padding: 0px 10px; font-size: 16px;'
                        "
                      >
                        fal fa-circle-notch fa-spin
                      </v-icon>
                    </button>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="geo_location_dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/main";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      geo_location_dialog: false,
      geo_location_add_text: "Submit",
      geo_location_add_submit: false,
      geo_location_add_latitude: "",
      geo_location_add_longitude: "",
      geo_location_add_address: "",
    };
  },
  computed: {
    ...mapGetters(["authCompanyData", "authCompanyGeoLocations"]),
  },
  methods: {
    ...mapActions(["fetch_company_geo_locations"]),
    launch_add_geo_location() {
      let _this = this;
      _this.geo_location_dialog = true;
      _this.locate();
    },
    submit_geo_location() {
      let _this = this;
      if (
        _this.geo_location_add_latitude === "processing..." ||
        _this.geo_location_add_longitude === "processing..." ||
        _this.geo_location_add_address === "processing..."
      ) {
        return;
      }
      _this.geo_location_add_text = "";
      _this.geo_location_add_submit = true;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        },
      });
      axios
        .post(`${BASE_URL}addgeolocation`, {
          company_id: _this.authCompanyData.id,
          latitude: _this.geo_location_add_latitude,
          longitude: _this.geo_location_add_longitude,
          address: _this.geo_location_add_address,
        })
        .then(function() {
          Toast.fire({
            icon: "success",
            title: "Geolocation address added successfully",
          });
          _this.fetch_company_geo_locations();
          _this.geo_location_add_latitude = "";
          _this.geo_location_add_longitude = "";
          _this.geo_location_add_address = "";
          _this.geo_location_dialog = false;
        })
        .catch(function(error) {
          let errors = error.response.data.error || null;
          Toast.fire({
            icon: "error",
            title: errors
              ? typeof errors === "string"
                ? errors
                : null || errors.latitude
                ? errors.latitude[0]
                : null || errors.longitude
                ? errors.longitude[0]
                : null || errors.address
                ? errors.address[0]
                : null
              : null ||
                error.response.data.error ||
                error.response.data.message ||
                "Geolocation address couldn't be added",
          });
        })
        .then(function() {
          _this.geo_location_add_submit = false;
          _this.geo_location_add_text = "Submit";
        });
    },
    locate() {
      let _this = this;
      _this.geo_location_add_latitude = "Processing...";
      _this.geo_location_add_longitude = "Processing...";
      _this.geo_location_add_address = "Processing...";
      if ("geolocation" in window.navigator) {
        window.navigator.geolocation.getCurrentPosition(function(position) {
          let currentLatitude = position.coords.latitude;
          let currentLongitude = position.coords.longitude;

          _this.geo_location_add_latitude = currentLatitude;
          _this.geo_location_add_longitude = currentLongitude;
          _this.getReverseGeocodingData(currentLatitude, currentLongitude);
        });
      }
    },
    getReverseGeocodingData(lat, lng) {
      let _this = this;
      let latlng = new window.google.maps.LatLng(lat, lng);
      // This is making the Geocode request
      let geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status !== window.google.maps.GeocoderStatus.OK) {
          alert(status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == window.google.maps.GeocoderStatus.OK) {
          let address = results[0].formatted_address;
          _this.geo_location_add_address = address;
        }
      });
    },
    validateLongitude() {
      const lon = parseFloat(this.geo_location_add_longitude);
      if (isNaN(lon) || lon < -180 || lon > 180) {
        this.geo_location_add_longitude = "";
      }
    },
    validateLatitude() {
      const lat = parseFloat(this.geo_location_add_latitude);
      if (isNaN(lat) || lat < -90 || lat > 90) {
        this.geo_location_add_latitude = "";
      }
    },
    delete_geo_location(location) {
      let _this = this;
      const Toast = _this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", _this.$swal.stopTimer);
          toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
        },
      });

      _this.$swal
        .fire({
          title: "Are you sure you want to delete this geolocation address?",
          showCancelButton: true,
          confirmButtonText: `Yes, Delete`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            // _this.$swal.fire(number >= 2 ? 'Appraisals deleted!' : 'Appraisal deleted!', '', 'success')
            axios
              .post(`${BASE_URL}deletegeolocation`, {
                geolocation_id: location.id,
              })
              .then(function() {
                Toast.fire({
                  icon: "success",
                  title:
                    "The geolocation address has been deleted successfully",
                });
              })
              .catch(function(error) {
                Toast.fire({
                  icon: "error",
                  title: "The geolocation address couldn't be deleted",
                });
                console.log(error);
              })
              .then(function() {
                _this.fetch_company_geo_locations();
              });
          }
        });
    },
  },
};
</script>

<style></style>
