<template>
  <div
    class="tab-pane fade"
    id="list-settings-branch-status"
    role="tabpanel"
    aria-labelledby="list-branch-status-list"
  >
    <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
      <div class="card-header">
        Display Employees by Branches
      </div>
      <div class="row card-body text-center py-3 mt-0">
        <p class="col-md-6 text-left py-1 mb-1">
          <strong>Status: </strong>
          <span
            v-if="authCompanyData.is_branch == 1"
            class="badge text-center badge-success"
            >ACTIVE</span
          >
          <span v-else class="badge text-center badge-warning">INACTIVE</span>
        </p>
        <p class="col-md-6 text-right py-1 mb-1">
          <label class="switch switch-secondary mr-3">
            <span v-if="formData.status">Enabled</span>
            <span v-else>Disabled</span>
            <input
              @change="switchStatus"
              type="checkbox"
              :checked="formData.status"
              :disabled="switchingStatus"
              v-model="formData.status"
            />
            <span class="slider"></span>
          </label>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import { BASE_URL } from "@/main";
  import { mapActions, mapState, mapGetters } from "vuex";
  export default {
    data() {
      return {
        formData: {
          status: false,
        },
        switchingStatus: false,
      };
    },
    computed: {
      ...mapState(["userToken"]),
      ...mapGetters(["authCompanyData", "validEmployeeId"]),
    },
    methods: {
      ...mapActions(["fetch_company_data"]),
      switchStatus() {
        let _this = this;
        _this.switchingStatus = true;
        const Toast = _this.$swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", _this.$swal.stopTimer);
            toast.addEventListener("mouseleave", _this.$swal.resumeTimer);
          },
        });
        axios
          .post(
            `${BASE_URL}updatebranchstatus`,
            {
              company_id: _this.authCompanyData.id,
            },
            {
              headers: {
                Authorization: `Bearer ${_this.userToken}`,
              },
            }
          )
          .then(function() {
            Toast.fire({
              icon: "success",
              title: "Status updated successfully",
            });
            _this.fetch_company_data().then(() => {
              _this.formData.status = _this.authCompanyData.is_branch;
            });
            _this.switchingStatus = false;
          })
          .catch(function(error) {
            let errors = error.response.data.error || null;
            Toast.fire({
              icon: "error",
              title: errors
                ? typeof errors === "string"
                  ? errors
                  : null
                : null ||
                  error.response.data.error ||
                  error.response.data.message ||
                  "Status couldn't be updated",
            });
          })
          .then(function() {
            _this.switchingStatus = false;
          });
      },
    },
    mounted() {
      const _this = this;
      _this.fetch_company_data();
      _this.formData.status = _this.authCompanyData.is_branch;
    },
  };
</script>

<style></style>
