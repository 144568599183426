<template>
  <div>
    <!-- <SideMenu /> -->
    <div
      v-if="paySlipView"
      class="main-content-wrap sidenav-open d-flex flex-column"
    >
      <div
        class="main-header pl-4"
        :class="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? 'pr-1'
            : 'pr-5'
        "
        style="z-index: 60;"
      >
        <div class="row">
          <div
            style="align-self: center;"
            class="logo col-md-1 col-lg-1 col-12 py-0"
          >
            <div
              class="dropdown animate__animated animate__fadeIn"
              style="font-size: 120%;"
            >
              <i
                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"
              ></i>
              <span class="ml-2" style="top: 4%; position: relative;"
                >Back</span
              >
            </div>
          </div>
          <div class="input-group-prepend"></div>
        </div>
        <div style="margin: auto"></div>
        <div
          class="header-part-right"
          :class="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? 'pr-0'
              : 'pr-5'
          "
        >
          <div class="dropdown">
            <button
              class="btn ripple m-1"
              type="button"
              @click="refresh_page()"
            >
              <span class="ul-btn__icon"
                ><i style="font-weight: bold;" class="i-Repeat-21"></i
              ></span>
            </button>
          </div>
          <!-- Grid menu Dropdown -->
          <!-- Notificaiton -->
          <div class="dropdown">
            <button
              type="button"
              class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })"
            >
              Log Time
            </button>
          </div>
          <div class="dropdown">
            <button
              type="button"
              class="btn btn-raised ripple btn-raised-secondary text-white"
              @click="fetchEmployeePayslips()"
            >
              All Pay Slips
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div
        class="main-content pt-0"
        :class="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? 'px-3'
            : null
        "
      >
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div
            class="row"
            :style="
              $vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
            "
          >
            <div class="container-fluid">
              <v-row>
                <v-col cols="12" class="m0-3" style="border-radius: 10px;">
                  <div
                    v-if="approvedEmployeePayrollBreakdownData"
                    id="employeeBreakdown"
                    class="card"
                  >
                    <div class="card-header px-3">
                      <h4>Employee Payslip Summary</h4>
                    </div>
                    <div class="card-body px-3 pt-1 pb-8">
                      <div class="noShowOnPrint d-print-none row">
                        <v-col cols="12" class="pb-0 text-right">
                          <button
                            @click="printEmployeeBreakdown"
                            type="button"
                            class="btn btn-outline-secondary m-1"
                          >
                            Print Payslip
                          </button>
                        </v-col>
                      </div>
                      <v-row class="add-mt">
                        <v-col cols="9">
                          <v-row>
                            <v-col cols="12">
                              <h3 class="text-left">
                                {{ authCompanyData.company_name }}
                              </h3>
                              <p>
                                <i>{{ authCompanyData.address }}</i>
                              </p>
                            </v-col>
                            <v-col cols="12">
                              <h5 style="font-weight: bold;">
                                Employee Name
                              </h5>
                              <p>
                                {{
                                  approvedEmployeePayrollBreakdownData
                                    .payslipData.employeePayslip.employeeName
                                }}
                              </p>
                              <h5 style="font-weight: bold;">
                                Employee Address
                              </h5>
                              <p>
                                {{
                                  approvedEmployeePayrollBreakdownData
                                    .payslipData.employeePayslip.employeeAddress
                                }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="3" class="text-center">
                          <img
                            :src="
                              authCompanyData.logo
                                ? host_url + authCompanyData.logo
                                : '/img/kylian-erp-logo.png'
                            "
                            alt=""
                            class="w-100"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="mx-1">
                        <v-col cols="12">
                          <v-row>
                            <v-col
                              class="py-3"
                              cols="3"
                              style="background: #e2e2e2;"
                            >
                              <h5 style="font-weight: bold;">
                                Department
                              </h5>
                            </v-col>
                            <v-col
                              class="py-3"
                              cols="3"
                              style="background: #e2e2e2;"
                            >
                              <h5 style="font-weight: bold;">
                                Designation
                              </h5>
                            </v-col>
                            <v-col
                              class="py-3"
                              cols="6"
                              style="background: #e2e2e2;"
                            >
                              <h5 style="font-weight: bold;">
                                Period
                              </h5>
                            </v-col>
                            <v-col cols="3" class="py-3">
                              <h5>
                                {{
                                  approvedEmployeePayrollBreakdownData
                                    .payslipData.employeePayslip.department
                                }}
                              </h5>
                            </v-col>
                            <v-col cols="3" class="py-3">
                              <h5>
                                {{
                                  approvedEmployeePayrollBreakdownData
                                    .payslipData.employeePayslip.designation
                                }}
                              </h5>
                            </v-col>
                            <v-col cols="6" class="py-3">
                              <h5>
                                {{
                                  approvedEmployeePayrollBreakdownData
                                    .payslipData.employeePayslip.period
                                }}
                              </h5>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col
                              cols="3"
                              class="py-3"
                              style="background: #e2e2e2;"
                            >
                              <h5 style="font-weight: bold;">
                                Payment Date
                              </h5>
                            </v-col>
                            <v-col
                              cols="3"
                              class="py-3"
                              style="background: #e2e2e2;"
                            >
                              <h5 style="font-weight: bold;">
                                Bank Name
                              </h5>
                            </v-col>
                            <v-col
                              cols="3"
                              class="py-3"
                              style="background: #e2e2e2;"
                            >
                              <h5 style="font-weight: bold;">
                                Bank Account Number
                              </h5>
                            </v-col>
                            <v-col
                              cols="3"
                              class="py-3"
                              style="background: #e2e2e2;"
                            >
                              <h5 style="font-weight: bold;">
                                Payment Method
                              </h5>
                            </v-col>
                            <v-col cols="3" class="py-3">
                              <h5>
                                {{
                                  approvedEmployeePayrollBreakdownData
                                    .payslipData.employeePayslip.paymentDate
                                }}
                              </h5>
                            </v-col>
                            <v-col cols="3" class="py-3">
                              <h5>
                                {{
                                  approvedEmployeePayrollBreakdownData
                                    .payslipData.employeePayslip.bankName
                                }}
                              </h5>
                            </v-col>
                            <v-col cols="3" class="py-3">
                              <h5>
                                {{
                                  approvedEmployeePayrollBreakdownData
                                    .payslipData.employeePayslip.accountNumber
                                }}
                              </h5>
                            </v-col>
                            <v-col cols="3" class="py-3">
                              <h5>
                                {{
                                  approvedEmployeePayrollBreakdownData
                                    .payslipData.employeePayslip.paymentMethod
                                }}
                              </h5>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col
                              v-if="
                                approvedEmployeePayrollBreakdownData.isTaxCompliant
                              "
                              cols="12"
                            >
                              <v-row>
                                <v-col
                                  cols="6"
                                  class="py-3"
                                  style="background: #e2e2e2; border-right: 10px solid #fff;"
                                >
                                  <h5 style="font-weight: bold;">
                                    Payments
                                  </h5>
                                </v-col>
                                <v-col
                                  cols="6"
                                  class="py-3"
                                  style="background: #e2e2e2; border-left: 10px solid #fff;"
                                >
                                  <h5 style="font-weight: bold;">
                                    Statutory Deductions
                                  </h5>
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="border-right: 10px solid #fff;"
                                >
                                  <v-row style="border-top: 2px solid #fff;">
                                    <v-col cols="6" class="py-3">
                                      <h5>
                                        Basic Salary
                                      </h5>
                                    </v-col>
                                    <v-col cols="6" class="text-right py-3">
                                      <h5>
                                        ₦{{
                                          approvedEmployeePayrollBreakdownData
                                            .payslipData.employeePayslip
                                            .salaryAmount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-for="(allowance,
                                    allowanceIndex) in approvedEmployeePayrollBreakdownData
                                      .payslipData.employeePayslipAllowances"
                                    :key="`allowance-${allowanceIndex}`"
                                    style="border-top: 2px solid #fff;"
                                  >
                                    <v-col cols="6" class="py-3">
                                      <h5>
                                        {{
                                          allowance.allowance +
                                            (allowance.note
                                              ? ` (${allowance.note})`
                                              : "")
                                        }}
                                      </h5>
                                    </v-col>
                                    <v-col cols="6" class="text-right py-3">
                                      <h5>
                                        ₦{{
                                          allowance.amount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                  <v-row style="border-top: 2px solid #8c8c8c;">
                                    <v-col
                                      cols="6"
                                      class="py-3"
                                      style="background: #e2e2e2;"
                                    >
                                      <h5 style="font-weight: bold;">
                                        Gross Pay
                                      </h5>
                                    </v-col>
                                    <v-col
                                      cols="6"
                                      style="background: #e2e2e2;"
                                      class="text-right py-3"
                                    >
                                      <h5 style="font-weight: bold;">
                                        ₦{{
                                          approvedEmployeePayrollBreakdownData
                                            .payslipData.employeePayslip
                                            .grossPayAmount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="border-left: 10px solid #fff;"
                                >
                                  <v-row
                                    v-for="(deduction,
                                    deductionIndex) in approvedEmployeeStatutoryDeductions"
                                    :key="
                                      `statutory-deduction-${deductionIndex}`
                                    "
                                    style="border-top: 2px solid #fff;"
                                  >
                                    <v-col cols="6" class="py-3">
                                      <h5>
                                        {{ deduction.deduction }}
                                      </h5>
                                    </v-col>
                                    <v-col cols="6" class="text-right py-3">
                                      <h5>
                                        ₦{{
                                          deduction.amount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                  <v-row style="border-top: 2px solid #8c8c8c;">
                                    <v-col
                                      cols="6"
                                      class="py-3"
                                      style="background: #e2e2e2;"
                                    >
                                      <h5 style="font-weight: bold;">
                                        Total Statutory Deductions
                                      </h5>
                                    </v-col>
                                    <v-col
                                      cols="6"
                                      style="background: #e2e2e2;"
                                      class="text-right py-3"
                                    >
                                      <h5 style="font-weight: bold;">
                                        ₦{{
                                          approvedEmployeePayrollBreakdownData
                                            .payslipData.employeePayslip
                                            .statutoryDeductionsAmount
                                            | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                  <v-row style="border-top: 2px solid #8c8c8c;">
                                    <v-col
                                      cols="12"
                                      class="py-3 pl-0"
                                      style="background: #e2e2e2; border-left: 10px solid #e2e2e2;"
                                    >
                                      <h5 style="font-weight: bold;">
                                        Non-Statutory Deductions
                                      </h5>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-for="(deduction,
                                    deductionIndex) in approvedEmployeePayrollBreakdownData
                                      .payslipData.employeePayslipDeductions"
                                    :key="`deduction-${deductionIndex}`"
                                    style="border-top: 2px solid #fff;"
                                  >
                                    <v-col cols="6" class="py-3">
                                      <h5>
                                        {{
                                          deduction.deduction +
                                            (deduction.note
                                              ? ` (${deduction.note})`
                                              : "")
                                        }}
                                      </h5>
                                    </v-col>
                                    <v-col cols="6" class="text-right py-3">
                                      <h5>
                                        ₦{{
                                          deduction.amount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>

                                  <v-row style="border-top: 2px solid #8c8c8c;">
                                    <v-col
                                      cols="6"
                                      class="py-3"
                                      style="background: #e2e2e2;"
                                    >
                                      <h5 style="font-weight: bold;">
                                        Total Non-Statutory Deductions
                                      </h5>
                                    </v-col>
                                    <v-col
                                      cols="6"
                                      style="background: #e2e2e2;"
                                      class="text-right py-3"
                                    >
                                      <h5 style="font-weight: bold;">
                                        ₦{{
                                          approvedEmployeePayrollBreakdownData
                                            .payslipData.employeePayslip
                                            .nonStatutoryDeductionsAmount
                                            | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                  <v-row style="border-top: 2px solid #8c8c8c;">
                                    <v-col
                                      class="py-3"
                                      cols="6"
                                      style="background: #e2e2e2;"
                                    >
                                      <h5 style="font-weight: bold;">
                                        Net Pay
                                      </h5>
                                    </v-col>
                                    <v-col
                                      cols="6"
                                      style="background: #e2e2e2;"
                                      class="text-right py-3"
                                    >
                                      <h5 style="font-weight: bold;">
                                        ₦{{
                                          approvedEmployeePayrollBreakdownData
                                            .payslipData.employeePayslip
                                            .netPayAmount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col v-else cols="12">
                              <v-row>
                                <v-col
                                  cols="6"
                                  class="py-3"
                                  style="background: #e2e2e2; border-right: 10px solid #fff;"
                                >
                                  <h5 style="font-weight: bold;">
                                    Payments
                                  </h5>
                                </v-col>
                                <v-col
                                  cols="6"
                                  class="py-3"
                                  style="background: #e2e2e2; border-left: 10px solid #fff;"
                                >
                                  <h5 style="font-weight: bold;">
                                    Deductions
                                  </h5>
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="border-right: 10px solid #fff;"
                                >
                                  <v-row style="border-top: 2px solid #fff;">
                                    <v-col cols="6" class="py-3">
                                      <h5>
                                        Basic Salary
                                      </h5>
                                    </v-col>
                                    <v-col cols="6" class="text-right py-3">
                                      <h5>
                                        ₦{{
                                          approvedEmployeePayrollBreakdownData
                                            .payslipData.employeePayslip
                                            .salaryAmount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    v-for="(allowance,
                                    allowanceIndex) in approvedEmployeePayrollBreakdownData
                                      .payslipData.employeePayslipAllowances"
                                    :key="`allowance-${allowanceIndex}`"
                                    style="border-top: 2px solid #fff;"
                                  >
                                    <v-col cols="6" class="py-3">
                                      <h5>
                                        {{
                                          allowance.allowance +
                                            (allowance.note
                                              ? ` (${allowance.note})`
                                              : "")
                                        }}
                                      </h5>
                                    </v-col>
                                    <v-col cols="6" class="text-right py-3">
                                      <h5>
                                        ₦{{
                                          allowance.amount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                  <v-row style="border-top: 2px solid #8c8c8c;">
                                    <v-col
                                      cols="6"
                                      class="py-3"
                                      style="background: #e2e2e2;"
                                    >
                                      <h5 style="font-weight: bold;">
                                        Gross Pay
                                      </h5>
                                    </v-col>
                                    <v-col
                                      cols="6"
                                      style="background: #e2e2e2;"
                                      class="text-right py-3"
                                    >
                                      <h5 style="font-weight: bold;">
                                        ₦{{
                                          approvedEmployeePayrollBreakdownData
                                            .payslipData.employeePayslip
                                            .grossPayAmount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col
                                  cols="6"
                                  style="border-left: 10px solid #fff;"
                                >
                                  <v-row
                                    v-for="(deduction,
                                    deductionIndex) in approvedEmployeePayrollBreakdownData
                                      .payslipData.employeePayslipDeductions"
                                    :key="`deduction-${deductionIndex}`"
                                    style="border-top: 2px solid #fff;"
                                  >
                                    <v-col cols="6" class="py-3">
                                      <h5>
                                        {{
                                          deduction.deduction +
                                            (deduction.note
                                              ? ` (${deduction.note})`
                                              : "")
                                        }}
                                      </h5>
                                    </v-col>
                                    <v-col cols="6" class="text-right py-3">
                                      <h5>
                                        ₦{{
                                          deduction.amount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>

                                  <v-row style="border-top: 2px solid #8c8c8c;">
                                    <v-col
                                      cols="6"
                                      class="py-3"
                                      style="background: #e2e2e2;"
                                    >
                                      <h5 style="font-weight: bold;">
                                        Total Deductions
                                      </h5>
                                    </v-col>
                                    <v-col
                                      cols="6"
                                      style="background: #e2e2e2;"
                                      class="text-right py-3"
                                    >
                                      <h5 style="font-weight: bold;">
                                        ₦{{
                                          approvedEmployeePayrollBreakdownData
                                            .payslipData.employeePayslip
                                            .nonStatutoryDeductionsAmount
                                            | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                  <v-row style="border-top: 2px solid #8c8c8c;">
                                    <v-col
                                      class="py-3"
                                      cols="6"
                                      style="background: #e2e2e2;"
                                    >
                                      <h5 style="font-weight: bold;">
                                        Net Pay
                                      </h5>
                                    </v-col>
                                    <v-col
                                      cols="6"
                                      style="background: #e2e2e2;"
                                      class="text-right py-3"
                                    >
                                      <h5 style="font-weight: bold;">
                                        ₦{{
                                          approvedEmployeePayrollBreakdownData
                                            .payslipData.employeePayslip
                                            .netPayAmount | number_with_commas
                                        }}
                                      </h5>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <div v-else class="main-content-wrap sidenav-open d-flex flex-column">
      <div class="main-header pl-4" style="z-index: 60;">
        <div class="row">
          <div
            style="align-self: center;"
            class="logo col-md-1 col-lg-1 col-12 py-0"
          >
            <div
              class="dropdown animate__animated animate__fadeIn"
              style="font-size: 120%;"
            >
              <i
                @click="hasHistory() ? $router.go(-1) : $router.push('/')"
                style="cursor: pointer; position: relative; top: 2px; color: #069;"
                class="fal fa-arrow-alt-circle-left mr-1"
              ></i>
              <span class="ml-2" style="top: 4%; position: relative;"
                >Back</span
              >
            </div>
          </div>
          <div class="input-group-prepend"></div>
        </div>
        <div style="margin: auto"></div>
        <div
          class="header-part-right"
          :class="
            $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
              ? 'pr-0'
              : 'pr-5'
          "
        >
          <div class="dropdown">
            <button
              class="btn ripple m-1"
              type="button"
              @click="refresh_page()"
            >
              <span class="ul-btn__icon"
                ><i style="font-weight: bold;" class="i-Repeat-21"></i
              ></span>
            </button>
          </div>
          <!-- Grid menu Dropdown -->
          <!-- Notificaiton -->
          <div class="dropdown">
            <button
              type="button"
              class="btn btn-outline-secondary m-1"
              @click="$router.push({ name: 'LogReport' })"
            >
              Log Time
            </button>
          </div>
          <!-- Notificaiton End -->
          <!-- User avatar dropdown -->
        </div>
      </div>
      <!-- ======== Body content start ========= -->
      <div
        class="main-content pt-0"
        :class="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? 'px-3'
            : null
        "
      >
        <div class="breadcrumb" style="margin-top: 1rem;">
          <div
            class="row"
            :style="
              $vuetify.breakpoint.name == 'xs' ? 'width: 110%;' : 'width: 80%;'
            "
          >
            <h1 class="my-0 row pb-0 pt-2">
              <span class="col-12 col-lg-4 col-md-4">Your Payslips</span>
              <v-row class="col-lg-8 col-md-8 col-12" style="float: right;">
                <v-col
                  offset-md="6"
                  offset-lg="6"
                  md="3"
                  lg="3"
                  cols="6"
                  class="pt-0"
                >
                  <v-select
                    class="remove-mb"
                    :items="allYears"
                    placeholder="Year"
                    dense
                    solo
                    messages=" *Filter by year"
                    :full-width="true"
                    v-model="yearSelected"
                  ></v-select>
                </v-col>
                <v-col lg="3" md="3" cols="6" class="pt-0">
                  <v-select
                    class="remove-mb"
                    :items="monthNamesSelector"
                    placeholder="Month"
                    dense
                    solo
                    messages="*Filter by month"
                    :full-width="true"
                    v-model="monthSelected"
                  ></v-select>
                </v-col>
              </v-row>
            </h1>

            <div class="col-md-12">
              <div class="card" style="border-radius: 10px;">
                <div class="card-body px-1 py-1 pt-0 pr-0">
                  <div
                    class="table-responsive"
                    style="border-radius: 10px; height: 75vh;
                                        overflow-y: scroll;
                                        padding-top: 0;
                                        padding-bottom: 0;"
                  >
                    <table class="table table-hover">
                      <thead>
                        <tr style="border: 2px solid #4440;">
                          <th scope="col" class="text-left">Payrun Name</th>
                          <th scope="col" class="text-left">From</th>
                          <th scope="col" class="text-left">To</th>
                          <th scope="col" class="text-left">Payment Date</th>
                          <th scope="col" class="text-left">Basic Salary</th>
                          <th scope="col" class="text-left">Allowances</th>
                          <th scope="col" class="text-left">Deductions</th>
                          <th scope="col" class="text-left">Net Pay</th>
                          <th scope="col" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(payslip, payslipIndex) in employeePayslips"
                          :key="`payslip-${payslipIndex}`"
                        >
                          <td class="text-left">{{ payslip.payRunName }}</td>
                          <td class="text-left">{{ payslip.fromDate }}</td>
                          <td class="text-left">{{ payslip.toDate }}</td>
                          <td class="text-left">{{ payslip.paymentDate }}</td>
                          <td class="text-left">
                            ₦{{ payslip.salaryAmount | number_with_commas }}
                          </td>
                          <td class="text-left">
                            ₦{{ payslip.allowancesAmount | number_with_commas }}
                          </td>
                          <td class="text-left">
                            ₦{{ payslip.deductionsAmount | number_with_commas }}
                          </td>
                          <td class="text-left">
                            ₦{{ payslip.netPayAmount | number_with_commas }}
                          </td>
                          <td class="text-center py-2">
                            <button
                              @click="
                                fetchEmployeeApprovedPayrunBreakdown(
                                  payslip.payRunId
                                )
                              "
                              type="button"
                              class="btn btn-raised ripple btn-raised-secondary text-white btn-sm"
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- ICON BG-->
        </div>
      </div>
      <!-- Footer Start -->
      <div class="flex-grow-1"></div>
      <!-- fotter end -->
    </div>
    <v-app>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-app>
  </div>
</template>

<script>
  import axios from "axios";
  import { ASSETS_URL, BASE_URL } from "@/main";
  import { mapState, mapGetters, mapActions } from "vuex";
  export default {
    data() {
      return {
        overlay: false,
        paySlipView: false,
        employeePayslips: [],
        approvedEmployeePayrollBreakdownData: null,
        firstYear: "",
        monthSelected: "All",
        yearSelected: "",
        currentPayRun: null,
      };
    },
    computed: {
      ...mapState(["userToken"]),
      ...mapGetters(["validEmployeeId", "authCompanyData"]),
      host_url() {
        return ASSETS_URL;
      },
      monthNamesSelector() {
        return ["All", ...this.monthNames];
      },
      allYears: function() {
        var t = [],
          e = new Date().getFullYear();
        t.push(this.firstYear);
        for (var a = this.firstYear; a <= e; a++)
          if (t.indexOf(a) == -1) {
            t.push(a.toString());
          }
        return t.reverse();
      },
      approvedEmployeeStatutoryDeductions() {
        const _this = this;
        let deductions = [];
        if (_this.approvedEmployeePayrollBreakdownData) {
          deductions = _this.approvedEmployeePayrollBreakdownData.payslipData.employeePayslipStatutoryDeductions.filter(
            (deduction) => deduction.is_for_employee
          );
        }
        return deductions;
      },
    },
    watch: {
      yearSelected() {
        const _this = this;
        _this.fetchEmployeePayslips();
      },
      monthSelected() {
        const _this = this;
        _this.fetchEmployeePayslips();
      },
    },
    methods: {
      ...mapActions(["fetch_all_employee_payslips"]),
      hasHistory: function() {
        return window.history.length > 2;
      },
      async fetchEmployeePayslips() {
        const _this = this;
        _this.overlay = true;

        await axios
          .get(
            `${BASE_URL}${
              _this.authCompanyData?.custom_payroll == true
                ? _this.authCompanyData?.custom_payroll_name + "-"
                : ""
            }payroll/employee-payslips?year=${_this.yearSelected +
              (_this.monthSelected.toLowerCase() !== "all"
                ? "&month=" + _this.monthSelected
                : "")}`,
            {
              headers: {
                Authorization: `Bearer ${_this.userToken}`,
              },
            }
          )
          .then(function(response) {
            _this.employeePayslips = response.data.employeePayslips;
          })
          .catch(function() {
            //
          })
          .then(function() {
            _this.overlay = false;
            _this.paySlipView = false;
          });
      },
      async fetchEmployeeApprovedPayrunBreakdown(payRunId) {
        const _this = this;
        _this.overlay = true;
        await axios
          .get(
            `${BASE_URL}${
              _this.authCompanyData?.custom_payroll == true
                ? _this.authCompanyData?.custom_payroll_name + "-"
                : ""
            }payroll/payruns/${payRunId}/approved/${_this.validEmployeeId}`,
            {
              headers: {
                Authorization: `Bearer ${_this.userToken}`,
              },
            }
          )
          .then(function(response) {
            _this.approvedEmployeePayrollBreakdownData = response.data;
            _this.paySlipView = true;
          })
          .catch(function() {
            //
          })
          .then(function() {
            _this.overlay = false;
          });
      },
      async printEmployeeBreakdown() {
        const _this = this;
        await _this.$htmlToPaper("employeeBreakdown", {
          ..._this.mixinPrintPortraitOptions,
          windowTitle:
            _this.approvedEmployeePayrollBreakdownData && _this.currentPayRun
              ? `Payslip for ${
                  _this.approvedEmployeePayrollBreakdownData.payslipData
                    .employeePayslip.employeeName
                } - ${_this.currentPayRun.payRunName +
                  " (" +
                  _this.currentPayRun.month +
                  ", " +
                  _this.currentPayRun.year +
                  ")"}`
              : window.document.title, // override the window title
        });
      },
    },
    mounted() {
      document.title = "Kylian ERP - Payslip";
      const _this = this;
      _this.yearSelected = new Date().getFullYear().toString();
      _this.firstYear = new Date(this.authCompanyData.created_at)
        .getFullYear()
        .toString();
    },
  };
</script>

<style scoped>
  .underline:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .lead {
    font-size: 130%;
  }

  th,
  td {
    text-align: center;
  }
</style>

<style>
  .upload-medical-bill div div,
  .upload-medical-bill-2 div div {
    cursor: pointer !important;
  }

  .mdi-calendar-month-outline {
    margin-right: 10px;
    color: #069 !important;
  }

  .remove-mb div .v-text-field__details {
    margin-bottom: 0px !important;
  }
</style>
